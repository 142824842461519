import './footer.scss';
import useAnalyticsEventTracker from '../../utilities/ga';

const Footer = ({info}) => {
    const gaEventTracker = useAnalyticsEventTracker('Footer');
    const {name , email} = info;
    return (
      <footer>
        <p>Development & design by {name}</p>
        <a onClick={()=>gaEventTracker(`Email`)} href={`mailto:${email}`}>{email}</a>
      </footer>
    );
  };
  
export default Footer;  