import './nav.scss';
import React, { useState } from 'react';

const fragmentMenuItem = (item) => {
  const [firstLetter, secondLetter, ...restletters] = item;
  const twoLetters = firstLetter + secondLetter;
  return (
    <>
    <span className="navbar__letter">{twoLetters}</span><span className="navbar__word">{restletters.join('')}</span>
    </>
  )
}
const Nav = ({navMenu}) => {

    const changeButtonMenu = (event) => {
      const buttonMenu$$ = event.currentTarget; 
      const collapsed = buttonMenu$$.getAttribute('aria-expanded');
      if (collapsed === 'false') {
          buttonMenu$$.setAttribute('aria-expanded', true);
      } else {
          buttonMenu$$.setAttribute('aria-expanded', false);
      }
      return collapsed;
    } 

    const [buttonClicked, setbuttonClicked] = useState(false);

    const collapseNavBar = (collapsed) => {
        if (collapsed === 'false') {
          setbuttonClicked(true);
        } else {
          setbuttonClicked(false);
        }
    }

    const collapseMenu = (event) => {
      const collapsed = changeButtonMenu(event);
      collapseNavBar(collapsed);
    }

    return (
      <section className={`header-nav ${buttonClicked ? 'collapse' : ''}`}>
        <div className="container">
            <button onClick={collapseMenu} id="buttonMegaMenu" title="open menú" aria-expanded="false">
                <span className="line line--top"></span>
                <span className="line line--middle"></span>
                <span className="line line--bottom"></span>
            </button>
            <nav className="navbar" aria-label="primary">
                <ul className="navbar__list">
                    { navMenu.map((item, index) => {
                    return (
                      <li className="navbar__item" key={`${JSON.stringify(item)}-${index}`}>
                          <a className="navbar__link" href={item.url} title={item.name}>{fragmentMenuItem(item.name)}</a>
                      </li>
                    )
                    })}
                </ul>
            </nav>
        </div>
      </section>
    );
  };
  
export default Nav;  