import './App.scss';
import initializeGA from './utilities/gaConfig';
import initializeGTM from './utilities/gtmConfig';
import { CV } from './database/cv';
import {Header, Footer, Nav} from './core'
import {Hero, AboutUs,  Contact, Timeline, Knowledge, Projects } from './components'
import { useEffect } from 'react';

const { info, navMenu, hero, aboutUs, experiences, knowledges, educations, contact, projects } = CV;

function App() {

  useEffect(() => {
    initializeGA();
    initializeGTM();
  }, [])
  
  return (
    <>
      <Header info={info}/>
      <Nav navMenu={navMenu}/>
      <main>
        <Hero hero={hero} />
        <AboutUs aboutUs={aboutUs}/>
        <Projects dataCV={projects} />
        <Timeline dataCV={educations} title="Education"/>
        <Knowledge knowledges={knowledges}/>
        <Timeline dataCV={experiences} title="Experience"/>
        <Contact contact={contact}/>
      </main>
      <Footer info={info}/>
    </>
  );
}

export default App;
