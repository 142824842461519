import "./header.scss";
import useAnalyticsEventTracker from "../../utilities/ga";

const Header = ({ info }) => {
  const gaEventTracker = useAnalyticsEventTracker("Header");
  const { logoUrl, email, linkedin, gitlab } = info;
  return (
    <header>
      <div className="container">
        <a href="/">
          <img
            width="180"
            className="logotipo"
            src={logoUrl}
            alt="logotipo pueyomir"
          />
        </a>
        <section className="social-media">
          <a
            onClick={() => gaEventTracker(`LinkedIn`)}
            className="social-media__link"
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-brands fa-linkedin-in"></span>
          </a>
          <a
            onClick={() => gaEventTracker(`Email`)}
            className="social-media__link"
            href={`mailto:${email}`}
          >
            <span className="fa-solid fa-at"></span>
          </a>
          <a
            onClick={() => gaEventTracker(`Gitlab`)}
            className="social-media__link"
            href={gitlab}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa-brands fa-gitlab"></span>
          </a>
        </section>
      </div>
    </header>
  );
};

export default Header;
