import './knowledge.scss';

const Knowledge = ({knowledges}) => {
     
    return (
      <section id="knowledge" className="knowledge">
      <h2 className="knowledge__title">Knowledge</h2>
        <ul className='knowledge__list' >
          { knowledges.map( (knowledge, index) => {
            return(
              <li className='knowledge__wrapper' key={`${JSON.stringify(knowledge)}-${index}`}>
                  <span className='knowledge__letter' >{knowledge.name[0]}</span>
                  <h3 className='knowledge__name' >{knowledge.name}</h3>
                  <ul className='knowledge__sub' >
                    { knowledge.subKnowledges.map( (subKnowledge, index) => {
                      return(
                        <li className='knowledge__sub-item' key={`${JSON.stringify(subKnowledge)}-${index}`}>{subKnowledge}</li>
                      )
                    })}
                  </ul>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

export default Knowledge; 