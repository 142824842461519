import './projects.scss';
import useAnalyticsEventTracker from '../../utilities/ga';

function Projects({dataCV}) {
  const gaEventTracker = useAnalyticsEventTracker('Proyectos')
  return (
    <section id="projects" className="projects">
        { dataCV.map( (project, index) => {
            const {urlProject, urlRepo, title, description, skills, image} = project;
            return (
                <article className="projects__item row" key = {`${JSON.stringify(project)}-${index}`}>
                    <div className="projects__header col-half">
                        <a href={urlProject}><img width="300" className="projects__image" src={image.url} alt={image.name} /></a>
                    </div>
                    <div className="projects__body col-half">
                        <h2 className="projects__title">{title}</h2>
                        <p className="projects__excerpt">{description}</p>
                        <div className="projects__footer">
                            <div className="projects__skills">
                                { skills.map( (skill, index) => {
                                    return(
                                        <span key = {`${JSON.stringify(skill)}-${index}`}>
                                            {skill}
                                        </span>
                                    )
                                })}
                            </div>
                            <a className="btn btn--primary projects__ctaProject" onClick={()=>gaEventTracker(`${title} Project`)} href={urlProject} target="_blank" rel="noreferrer">See project</a>
                            <a className="btn btn--primary" onClick={()=>gaEventTracker(`${title} Repo `)} href={urlRepo} target="_blank" rel="noreferrer">See repository</a>
                        </div>
                    </div>
                </article>
            )
        })}
    </section>
  )
}

export default Projects