import './about-us.scss';

const AboutUs = ({aboutUs}) => {
  
  const {description , image} = aboutUs;
 
    return (
      <section id="aboutMe" className="about-us">
        <div className="row">
            <div className="about-us__img col-half">
                <img src={image.url} alt={image.name} />
            </div>
            <div className="about-us__text col-half">
                <p>{description}</p>
            </div>
        </div>
      </section>
    );
  };

export default AboutUs;  