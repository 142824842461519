import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-TZQVPXB'
}

const initializeGTM = () => {
    TagManager.initialize(tagManagerArgs)
}
export default initializeGTM;

