import './timeline.scss';

const Timeline = ({dataCV, title}) => {
    return (
      <section id={title.toLowerCase()} className="timeline">
      <h2 className="timeline__title">{title}</h2>
        <ul className='timeline__wrapper'>
        {
          dataCV.map( (experience, index) => {
            return(
              <li className='timeline__item' key = {`${JSON.stringify(experience)}-${index}`}>
                <div className="timeline__wrapper-image">
                  <img width="60" className='timeline__logo' src={experience.logo} alt="logo" />
                </div>
                <div className='timeline__content'>
                    <p className='timeline__date'>{experience.date}</p>
                    <p className='timeline__entity'>{experience.entity}</p>
                    <p className='timeline__specialty'>{experience.specialty}</p>
                </div>
              </li>
            )
          })
        }
        </ul>
      </section>
    );
  };

export default Timeline;  