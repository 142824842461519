import './contact.scss';
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import useAnalyticsEventTracker from '../../utilities/ga';

const INITIAL_STATE = {
    userEmail: '',
    userName: '',
    userMessage: '',
    userPrivacy: false,
};

const Contact = ({contact}) => {
    const gaEventTracker = useAnalyticsEventTracker('Contact');
    const {title , subtitle} = contact;
    const [state, setState] = useState(INITIAL_STATE);

    const handleInput = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };
    const handleCheckbox = (event) => {
        const {name, checked} = event.target;
        setState({...state, [name]: checked});
    };

    const submitForm = (event) => {
        event.preventDefault();
        gaEventTracker(`Click Submit`)
        const { userEmail, userName, userMessage, userPrivacy } = state;
        if (!userEmail || !userName || !userMessage || !userPrivacy) {
            console.log('Debes rellenar todos los campos');
            return;
        }
        emailjs.sendForm('service_qoy2il5', 'template_2vyqpwh', event.target, 'OXUnwQ2agZq5cnaME')
        .then((result) => {
            console.log('send email', result.text);
            gaEventTracker(`Submited by ${userEmail}`)
        }, (error) => {
            gaEventTracker(`No submited`);
            console.log('error email', error.text);
        });
        console.log(state);
        setState(INITIAL_STATE);
    }

    return (
        <section id="contact" className="contact">
            <div className="contact__intro">
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </div>
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-half">
                        <label htmlFor="formEmail">Email</label>
                        <input 
                            type="email"
                            name="userEmail"
                            value={state.userEmail}
                            onChange={handleInput}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                            required
                        />
                    </div>
                    <div className="col-half">
                        <label htmlFor="formName">Name</label>
                        <input 
                            type="text" 
                            name="userName"
                            value={state.userName}
                            onChange={handleInput}
                            required
                        />
                    </div>
                </div>
                <label htmlFor="formMessage">Message</label>
                <textarea 
                    name="userMessage"
                    value={state.userMessage}
                    onChange={handleInput}
                    required
                ></textarea>
                <div className="row">
                    <div className="checkbox col">
                        <input className={`checkbox__input ${state.userPrivacy ? 'checked' : ''}`}
                        type="checkbox"
                        name="userPrivacy"
                        checked={state.userPrivacy}
                        onChange={handleCheckbox}
                        required
                        />
                        <label className="checkbox__label" htmlFor="signCheck">I have read and accepted <a href="#a" target="_blank" rel="noopener noreferrer">the privacy policies</a> of the website.</label>
                    </div>
                    <div className="col m-l-auto">
                        <button className="btn btn--primary" type="submit">Send message !</button>
                    </div>
                </div>
            </form>
        </section>
    );
  };

export default Contact;  